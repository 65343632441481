<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      tab: 'LotNation'
    }
  }
}
</script>
