<template>
  <div class="chart-container">
    <div v-if="isPage('LotteryChart')">
      <lot-nav-bar
        title="走势图表"
        :formChart="true"
        :curTab="curTab"
        @handleChangeTab="handleChangeTab" />
      <div class="content">
        <!-- <transition> -->
          <keep-alive>
            <component :is="curTab" />
          </keep-alive>
        <!-- </transition> -->
      </div>
    </div>
    <router-view v-if="isPage('LotteryChartDetail')" />
  </div>
</template>

<script>
import LotNavBar from '@/components/LotNavBar'
import LotHH from './contents/LotHH'
import LotPK10 from './contents/LotPK10'
import Lot11P5 from './contents/Lot11P5'
import LotQ3 from './contents/LotQ3'
import Happy81012 from './contents/Happy81012'
import LotNation from './contents/LotNation'
import LotOversea from './contents/LotOversea'
import LotOther from './contents/LotOther'

export default {
  components: {
    LotNavBar,
    LotHH,
    LotPK10,
    Lot11P5,
    LotQ3,
    Happy81012,
    LotNation,
    LotOversea,
    LotOther
  },
  data () {
    return {
      curTab: 'LotHH'
    }
  },
  methods: {
    handleChangeTab (tab) {
      this.curTab = tab.id
    },
    isPage (routeName) {
      return this.$route.name === routeName
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  .content {
    background-color: #FFFFFF;
    border-radius: 0 0 5px 5px;
  }
}
@media (max-width: 575.98px) {
  .chart-container {
    width: 100%;
    // background-color: #F3F9FF;
    .content {
      margin: 10px 0;
      border-radius: 0;
    }
  } 
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .chart-container {
    width: 100%;
    // background-color: #F3F9FF;
    .content {
      margin: 10px 0;
      border-radius: 0;
    }
  } 
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .chart-container {
    width: 98%;
    padding: 20px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .chart-container {
    width: 97%;
    padding: 20px 0;
  }
}
@media (min-width: 1200px) {
  .chart-container {
    padding: 20px 0;
  }
}
</style>
