<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      tab: 'Happy81012'
    }
  }
}
</script>
